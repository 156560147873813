import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { createDropdown, addToolbarToDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

class SimorgAttendeeNotificationPlugin extends Plugin {
    init() {
        const editor = this.editor;
        const buttons = new Map();
        buttons.set('{RESOURCE}', 'Resource Name');
        buttons.set('{START_DATE}', 'Start Date Time');
        buttons.set('{END_DATE}', 'End Date Time');
        buttons.set('{COURSE_NAME}', 'Course Name');
        buttons.set('{TASK_NAME}', 'Task Name');

        // buttons.set('{CUSTOMER_NAME}', 'Customer Name');
        // buttons.set('{FULL_NAME}', 'Full Name');
        // buttons.set('{POSITION}', 'Position');

        const btns = [];

        editor.ui.componentFactory.add('simorgAttendeeNotification', locale => {
            const view = createDropdown( locale );
            view.buttonView.set( {
                label: 'Constants',
                withText: true
            } );
            
            for (let [key, value] of buttons) {

                const btn = new ButtonView(locale);
                btn.set( {
                    label: value,
                    withText: true
                });

                btn.on( 'execute', () => {
                    editor.model.change( writer => {
                        writer.insertText( key, editor.model.document.selection.getFirstPosition() );
                    } );
                });

                btns.push(btn);
            }
            
            addToolbarToDropdown( view, btns );

            view.toolbarView.isVertical = true;
            
           

            return view;
        } );
    }
};

export default SimorgAttendeeNotificationPlugin;