import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { createDropdown, addToolbarToDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

class AtoTrainingCertificatePlugin extends Plugin {
  init() {
    const { editor } = this;
    const buttons = new Map();
    buttons.set('{COURSE_CODE}', 'Course Code');
    buttons.set('{COURSE_NAME}', 'Course Name');
    buttons.set('{COURSE_DATE}', 'Course Date');
    buttons.set('{ATTENDEE_NAME}', 'Attendee Name');
    buttons.set('{LOCATION}', 'Location');
    buttons.set('{ENROLLMENT_ID}', 'Enrollment ID');
    buttons.set('{INSTRUCTORS}', 'Instructors');
    buttons.set('{ATTENDEES}', 'Attendees');
    buttons.set('{OPERATION_OPTIONS}', 'Operating Option');
    buttons.set('{ASSIGNED_TASKS}', 'Assigned Tasks');
    buttons.set('{LICENSE_NUMBER}', 'License Number');
    buttons.set('{CERTIFICATE_NUMBER}', 'Certificate Number');
    buttons.set('{EXPIRY_DATE}', 'Expiry Date');
    buttons.set('{STUDENT_BIRTHDAY}', 'Student Birthday');
    buttons.set('{COUNTRY_OF_ISSUE}', 'Country Of Issue');
    buttons.set('{COURSE_START_DATE}', 'Course Start Date');
    buttons.set('{COURSE_END_DATE}', 'Course End Date');

    const btns = [];

    editor.ui.componentFactory.add('simorgAtoTrainingCertificate', (locale) => {
      const view = createDropdown(locale);
      view.buttonView.set({
        label: 'Constants',
        withText: true,
      });

      for (const [key, value] of buttons) {
        const btn = new ButtonView(locale);
        btn.set({
          label: value,
          withText: true,
        });

        btn.on('execute', () => {
          editor.model.change((writer) => {
            writer.insertText(key, editor.model.document.selection.getFirstPosition());
          });
        });

        btns.push(btn);
      }

      addToolbarToDropdown(view, btns);

      view.toolbarView.isVertical = true;

      return view;
    });
  }
}

export default AtoTrainingCertificatePlugin;
