import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import {
  createDropdown,
  addToolbarToDropdown,
} from "@ckeditor/ckeditor5-ui/src/dropdown/utils";
import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";

class LogTemplate extends Plugin {
  init() {
    const editor = this.editor;
    const buttons = new Map();
    buttons.set("{PAGE_LOGO}", "Logo");
    buttons.set("{GEN_PAGE_TITLE}", "Title");
    buttons.set("{NO_SHOW}", "No Show Info");
    buttons.set("{START_DATE}", "Start Date");
    buttons.set("{ACTUAL_START_TIME}", "Actual Start Time");
    buttons.set("{ACTUAL_END_TIME}", "Actual End Time");
    buttons.set("{SESS_DATE}", "Start Date (DD/MM/YYYY)");
    buttons.set("{END_DATE}", "End Date");
    buttons.set("{ACT_START_DATE}", "Actual Start Date");
    buttons.set("{ACT_END_DATE}", "Actual End Date");
    buttons.set("{DAY_OF_MONTH}", "Date of Log");
    buttons.set("{MONTH_OF_YEAR}", "Month of Log");
    buttons.set("{YEAR_OF_LOG}", "Year of Log");
    buttons.set("{ACTUAL_DURATION}", "Actual Duration");
    buttons.set("{ACTUAL_TIME_IPAD}", "Actual Time");
    buttons.set("{QR_RATE}", "QR Rate");
    buttons.set("{DOWNTIME_VALUE}", "Downtime Value");
    buttons.set("{SESS_ID}", "Session ID");
    buttons.set("{TRAINEE_LIST}", "Attendee List (With Instructor)");
    buttons.set("{DEV_NO}", "Device ID");
    buttons.set("{SERIAL_GN}", "Serial No");
    buttons.set("{VERSION}", "Version");
    buttons.set("{MOTION_PARAM}", "Motion Param");
    buttons.set("{DEV_TYPE}", "Device Type");
    buttons.set("{COMPANY_NAME}", "Company Name");
    buttons.set("{OCCUPIED_FOR}", "Used For");
    buttons.set("{SESS_REMARK}", "Session Remarks");
    buttons.set("{OPERATOR_SIGNATURE}", "Operator Signature");
    buttons.set("{INSTRUCTOR_SIGNATURE}", "Instructor Signature");
    buttons.set("{PREFLIGHT}", "Preflight Info");
    buttons.set("{INTERRUPTIONS}", "Interruptions");
    buttons.set("{SERVICE_REQUESTS}", "Service Requests Details");
    buttons.set("{SFL_LOG_ID}", "Training Log Id");
    buttons.set("{REGISTRATION_NO}", "Device Registration Id");
    buttons.set("{ATTENDEES_NAMES}", "Attendees Names");
    buttons.set("{INSTRUCTOR_NAME}", "Instructor Name");
    buttons.set("{TRAINING_TYPE}", "Training Type");
    buttons.set("{DRY_WET}", "Dry/Wet");
    buttons.set("{LOST_TRAINING_TIME}", "Lost Training Time");
    buttons.set("{INSTRUCTOR_QUALIFICATION}", "Instructor Qualification");
    buttons.set("{PLANNED_START_TIME}", "Planned Start Time");
    buttons.set("{PLANNED_END_TIME}", "Planned End Time");
    buttons.set("{JOB_NUMBER}", "Job Number");
    buttons.set("{DFT_IN_SESSION}", "DFT in Session");
    buttons.set("{USE_CASE}", "Use Case");

    const btns = [];

    editor.ui.componentFactory.add("logTemplate", (locale) => {
      const view = createDropdown(locale);
      view.buttonView.set({
        label: "Log Constants",
        withText: true,
      });

      for (let [key, value] of buttons) {
        const btn = new ButtonView(locale);
        btn.set({
          label: value,
          withText: true,
        });

        btn.on("execute", () => {
          editor.model.change((writer) => {
            writer.insertText(
              key,
              editor.model.document.selection.getFirstPosition()
            );
          });
        });

        btns.push(btn);
      }

      addToolbarToDropdown(view, btns);

      view.toolbarView.isVertical = true;

      return view;
    });
  }
}

export default LogTemplate;
