import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { createDropdown, addToolbarToDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

class SimorgNotificationPlugin extends Plugin {
    init() {
        const editor = this.editor;
        const buttons = new Map();
        buttons.set('{SIM}', 'Simulator Name');
        buttons.set('{RESERVATION_START_DATE}', 'Reservation Start Date Time');
        buttons.set('{RESERVATION_END_DATE}', 'Reservation End Date Time');
        buttons.set('{COMPANY_NAME}', 'Company Name');
        
        const btns = [];

        editor.ui.componentFactory.add('simorgNotification', locale => {
            const view = createDropdown( locale );
            view.buttonView.set( {
                label: 'Mail Constants',
                withText: true
            } );
            
            for (let [key, value] of buttons) {

                const btn = new ButtonView(locale);
                btn.set( {
                    label: value,
                    withText: true
                });

                btn.on( 'execute', () => {
                    editor.model.change( writer => {
                        writer.insertText( key, editor.model.document.selection.getFirstPosition() );
                    } );
                });

                btns.push(btn);
            }
            
            addToolbarToDropdown( view, btns );

            view.toolbarView.isVertical = true;
            
           

            return view;
        } );
    }
};

export default SimorgNotificationPlugin;